import { Box, Input } from "@mui/material"
import { useRef } from "react"
import { SearchIcon } from "../shared/Icons"
import { useDispatch, useSelector } from "react-redux"
import { initSearch, updateSearch } from "../../reducers/filterSlice"

const SearchBar = ({ isMobile }) => {
	const mode = useSelector((state) => state.layout.mode)
	const dispatch = useDispatch()
	const searchValue = useSelector((state) => state.filter.query.search)

	const ref = useRef(null)

	const handleChange = (event) => {
		dispatch(initSearch())
		dispatch(updateSearch(event.target.value))
	}
	const handleIconClick = () => {
		ref.current.focus()
	}
	if (isMobile) {
		if (ref.current) {
			var searchFieldPosition = ref.current.getBoundingClientRect().top
		}
		var offsetPosition = searchFieldPosition + window.scrollY - 55

		const handleInputFocus = () => {
			// Scroll to the top of the element when input is focused
			setTimeout(() => {
				window.scrollTo({ top: offsetPosition, behavior: "smooth" })
			}, 0)
		}
		return (
			<Box display={"flex"} width={"90%"} height={"40px"}>
				<Box
					display={"flex"}
					justifyContent={"center"}
					margin={"2px"}
					width={"10%"}
					paddingLeft={1}
				>
					<SearchIcon mode={mode} handleClick={handleIconClick} />
				</Box>
				<Box borderRight={1} width={"90%"}>
					<Input
						onFocus={handleInputFocus}
						onClick={handleInputFocus}
						value={searchValue}
						onChange={(event) => handleChange(event)}
						inputRef={ref}
						disableUnderline
						fullWidth
						inputProps={{ spellCheck: "false" }}
						sx={{ height: "40px", fontSize: "20px", paddingLeft: 1 }}
					></Input>
				</Box>
			</Box>
		)
	}
	return (
		<>
			<Box display={"flex"} justifyContent={"center"} margin={"2px"}>
				<SearchIcon mode={mode} handleClick={handleIconClick} />
			</Box>
			<Box borderRight={1} width={"63.3%"}>
				<Input
					value={searchValue}
					onChange={(event) => handleChange(event)}
					inputRef={ref}
					disableUnderline
					fullWidth
					inputProps={{ spellCheck: "false" }}
					sx={{ height: "45px", fontSize: "20px", paddingLeft: 1 }}
				></Input>
			</Box>
		</>
	)
}

export default SearchBar
