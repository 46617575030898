import { Box, Typography } from "@mui/material"
import { ToggleAvailableToPurchaseButton } from "../shared/Icons"
import { useSelector } from "react-redux"

const ToggleAvailableToPurchaseSelection = () => {
    const mode = useSelector((state) => state.layout.mode)


    return (
        <Box
            height={"45px"}
            boxSizing={"content-box"}
            alignItems={"center"}
            display={"flex"}
            justifyContent={"space-between"}
            borderBottom={1} 
					
				>
					<Typography paddingLeft={"18px"} variant="thin22">
						{"AVAILABLE TO PURCHASE"}
                        
					</Typography>
                    <Box margin={"4px"}>
                        <ToggleAvailableToPurchaseButton mode={mode} size={19}></ToggleAvailableToPurchaseButton>
                    </Box>


        
        </Box>
    )
}

export default ToggleAvailableToPurchaseSelection
