import { Box, Link } from "@mui/material"
import { NavLink, useNavigate } from "react-router-dom"
import { PbffLogo } from "./shared/Icons"
import { useSelector } from "react-redux"
import useIsMobile from "./shared/useIsMobile"

const NavBar = () => {
	const mode = useSelector((state) => state.layout.mode)
	const navigate = useNavigate()

	const activeColor = mode === "light" ? "black" : "white"
	const isMobile = useIsMobile()
	const handleLogoClick = () => {
		navigate("/")
		window.location.reload()
	}

	const mainTitle = "PUBLICATIONS"
	const submitTitle = "SUBMIT"
	const aboutTitle = "ABOUT"

	const navElemantStyle = {
		color: "text.secondary",
		component: NavLink,
		variant: "navbarlink",
		underline: "none",

		sx: { "&.active": { color: activeColor }, fontSize: 40, fontWeight: 530 },
	}

	if (isMobile) {
		return (
			<Box
				display={"flex"}
				justifyContent={"center"}
				flexDirection={"column"}
				borderBottom={1}
				
			>
				<Box
					overflow={"hidden"}
					zIndex={1000}
					top={0}
					height={"50px"}
					position={"fixed"}
					width={"100%"}
					bgcolor={"background.default"}
					justifyContent={"center"}
				>
					<Box
						marginTop={1}
						display={"flex"}
						justifyContent={"space-between"}
						marginX={"1.5rem"}
					>
						<Link {...navElemantStyle} to={"/"}>
							{mainTitle}
						</Link>
						<Link {...navElemantStyle} to={"/submit/"}>
							{submitTitle}
						</Link>
						<Link {...navElemantStyle} to={"/about"} component={NavLink}>
							{aboutTitle}
						</Link>
					</Box>
				</Box>
				<Box
					onClick={() => handleLogoClick()}
					sx={{ cursor: "pointer" }}
					display={"flex"}
					justifyContent={"center"}
					paddingX={2}
					paddingY={2}
					marginTop={"45px"}

				>
					<PbffLogo mode={mode}></PbffLogo>
				</Box>
			</Box>
		)
	}
	return (
		<Box
			width={"100%"}
			display={"flex"}
			justifyContent={"space-between"}
			border={1}
			borderTop={1}
			height={"235px"}
			marginRight={"12px"}
		>
			<Box
				onClick={() => handleLogoClick()}
				sx={{ cursor: "pointer" }}
				width={"60%"}
			>
				<PbffLogo mode={mode}></PbffLogo>
			</Box>
			<Box
				display={"flex"}
				marginRight={4}
				width={"40%"}
				justifyContent={"space-between"}
				fontSize={"40px"}
				height={"50px"}
			>
				<Link padding={0} {...navElemantStyle} to={"/"}>
					{mainTitle}
				</Link>
				<Link {...navElemantStyle} to={"/submit/"}>
					{submitTitle}
				</Link>
				<Link {...navElemantStyle} to={"/about"} component={NavLink}>
					{aboutTitle}
				</Link>
			</Box>
		</Box>
	)
}

export default NavBar
