import { createSlice } from "@reduxjs/toolkit"
import { getModeInStorage, setModeInStorage } from "../utils/localStorage"

const mode = getModeInStorage()

const initialState = {
	gridView: true,
	gridSize: 3,
	mode: mode ? mode : "light",
	mobileGridSize: 1,
}

export const layoutSlice = createSlice({
	name: "layout",
	initialState,
	reducers: {
		toGridView: (state) => {
			if (!state.gridView) {
				state.gridView = !state.gridView
			}
		},
		toListView: (state) => {
			if (state.gridView) {
				state.gridView = !state.gridView
			}
		},
		zoomIn: (state) => {
			if (state.mobileGridSize == 2) {
				state.mobileGridSize = 1
			}
			if (state.gridSize > 1 && state.gridView) {
				state.gridSize -= 1
			}
		},
		zoomOut: (state) => {
			if (state.mobileGridSize == 1) {
				state.mobileGridSize = 2
			}
			if (state.gridSize < 5 && state.gridView) {
				state.gridSize += 1
			}
		},
		toLightMode: (state) => {
			if (state.mode !== "light") {
				state.mode = "light"
				setModeInStorage("light")
			}
		},
		toDarkMode: (state) => {
			if (state.mode !== "dark") {
				state.mode = "dark"
				setModeInStorage("dark")
			}
		},
	},
})

export const {
	toGridView,
	toListView,
	zoomIn,
	zoomOut,
	toLightMode,
	toDarkMode,
} = layoutSlice.actions

export default layoutSlice.reducer
