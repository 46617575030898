import LeftSection from "./secondary-view/LeftSection"
import MiddleSection from "./secondary-view/MiddleSection"
import RightSection from "./secondary-view/RightSection"

import { Box, Typography } from "@mui/material"
import { XIcon } from "./shared/Icons"
import { useSelector } from "react-redux"
import RelatedContainer from "./book-details/RelatedContainer"
import { useEffect, useRef } from "react"
import PreviewImageView from "./preview/PreviewImageView"
import PreviewDetailView from "./preview/PreviewDetailView"

const PreviewView = ({ book, onClose }) => {
	const mode = useSelector((state) => state.layout.mode)
	const categoryTags = useSelector((state) => state.submitBook.categoryTags)
	book.categoryTags = categoryTags
	const ref = useRef()

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			onClose()
		}
	}
	const boxShadow =
		mode === "light"
			? "0 0 10px rgba(0, 0, 0, 0.3)"
			: "0 0 10px rgba(255, 255, 255, 0.3)"

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside)

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	})

	return (
		<Box
			sx={{
				backgroundColor: "background.default",
				transform: "translate('-50%', '-50%')",
				boxShadow: boxShadow,
			}}
			position={"absolute"}
			top={"5%"}
			left={"5%"}
			width={"90%"}
			height={"90%"}
			padding={"10px"}
			zIndex={100}
			ref={ref}
		>
			<Box
				width={"100%"}
				display={"flex"}
				justifyContent={"space-between"}
				paddingBottom={"2px"}
			>
				<Box></Box>
				<Typography>Preview</Typography>
				<XIcon mode={mode} handleClick={onClose}></XIcon>
			</Box>
			<Box display={"flex"} height={"95%"} borderBottom={1}>
				<LeftSection component={<RelatedContainer />} />
				<MiddleSection component={<PreviewImageView book={book} />} />
				<RightSection component={<PreviewDetailView book={book} />} />
			</Box>
		</Box>
	)
}

export default PreviewView
