
export const dropDownData = {
	GENRE: [
		"Artist's book",
		"Artist's monograph",
		"Catalogue raisonné",
		"Collage",
		"Digital art",
		"Drawing",
		"Exhibition catalogue",
		"Illustration",
		"Installation",
		"Mixed media",
		"Painting",
		"Performance",
		"Photograph",
		"Printmaking",
		"Sculpture",
		"Textile art",
		"Video art",
		"Zine"
	],
	THEME: [
		"Archive",
		"Collaborative",
		"Collective",
		"Conceptual",
		"Documentary",
		"Experimental",
		"Family",
		"Found material",
		"Nature"
	  ],
	
}

export const submitDropdownGenreData = 
	[
		"Artist's book",
		"Artist's monograph",
		"Catalogue raisonné",
		"Collage",
		"Digital art",
		"Drawing",
		"Exhibition catalogue",
		"Illustration",
		"Installation",
		"Mixed media",
		"Painting",
		"Performance",
		"Photograph",
		"Printmaking",
		"Sculpture",
		"Textile art",
		"Video art",
		"Zine"
	]


export const submitDropDownPublisherData = {
	PUBLISHER: [
		"Self-Published"
	]
}

export const submitDropdownThemeData = 
	[
		"Archive",
		"Collaborative",
		"Collective",
		"Conceptual",
		"Documentary",
		"Experimental",
		"Family",
		"Found material",
		"Nature"
	]


export const sortByDropdownData = {
	"SORT BY": ["Artist", "Year"],
}

export const dashboardFilterData = {
	Artbooks: ["All", "New", "Published", "Unpublished"],
}

export const dashboardUserData = {
	Manage: ["Admins", "Register admin"],
}

export const data = [...Array(100)]
