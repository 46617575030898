import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
const Loading = ({ isLoading }) => {
	return (
		<>
			{isLoading ? (
				<Box position={"fixed"} bottom={15} right={15} zIndex={1000}>
					<CircularProgress></CircularProgress>
				</Box>
			) : null}
		</>
	)
}

export default Loading
