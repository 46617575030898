import { Box } from "@mui/material"
import GridContainer from "./GridContainer"
import ListContainer from "./ListContainer"
import { useCallback, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useGetBooksQuery } from "../../store/apiSlice"
import { incrementPage } from "../../reducers/filterSlice"
import Loading from "../shared/Loading"
import useIsMobile from "../shared/useIsMobile"

const CollectionView = ({ size, isGridView }) => {
	const query = useSelector((state) => state.filter.query)
	const ref = useRef(null)
	const { data, isFetching } = useGetBooksQuery(query)
	const isMobile = useIsMobile()
	const observer = useRef()
	const dispatch = useDispatch()

	const lastElementRef = useCallback((node) => {
		if (isFetching) return
		if (observer.current) observer.current.disconnect()
		observer.current = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting && query.page < data.pageCount) {
				dispatch(incrementPage())
			}
		})
		if (node) observer.current.observe(node)
	})

	if (isMobile) {
		return (
			<>
				<Loading isLoading={isFetching}></Loading>
				<Box id="collection-view" className="scrollable-mobile-content">
					<GridContainer
						ref={ref}
						className={"grid-container"}
						lastElementRef={lastElementRef}
						data={data}
						gridSize={size}
					></GridContainer>
				</Box>
			</>
		)
	}

	return (
		<>
			<Loading isLoading={isFetching}></Loading>
			<Box id="collection-view" className="scrollable-content" border={1}>
				{isGridView ? (
					<GridContainer
						ref={ref}
						className={"grid-container"}
						lastElementRef={lastElementRef}
						data={data}
						gridSize={size}
					></GridContainer>
				) : (
					<ListContainer
						lastElementRef={lastElementRef}
						data={data}
					></ListContainer>
				)}
			</Box>
		</>
	)
}

export default CollectionView
