import { createApi } from "@reduxjs/toolkit/query/react"
import { fetchBaseQuery } from "@reduxjs/toolkit/query"
import { removeCredentials, setCredentials } from "../reducers/authSlice"

const baseUrl = process.env.REACT_APP_API_BASE_URL

const baseQuery = fetchBaseQuery({
	baseUrl: baseUrl,
	credentials: "include",
	prepareHeaders: (headers, { getState }) => {
		const accessToken = getState().auth.accessToken

		if (accessToken) {
			headers.set("authorization", `Bearer ${accessToken}`)
		}
		return headers
	},
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
	let response = await baseQuery(args, api, extraOptions)
	if (
		(response?.error && response?.error?.status == 403) ||
		response?.error?.status == 401
	) {
		// try to get a new token
		const refreshResponse = await baseQuery("/user/refresh", api, {
			...extraOptions,
		})
		if (refreshResponse?.data) {
			// store the new token

			api.dispatch(setCredentials(refreshResponse.data))
			// retry the initial query
			response = await baseQuery(args, api, extraOptions)
		} else {
			if (
				refreshResponse?.error?.status === 403 ||
				response?.error?.status == 401
			) {
				refreshResponse.error.data.message = "Login expired"
				api.dispatch(removeCredentials())
			}
			return refreshResponse
		}
	}
	return response
}

/* export const authApi = createApi({
	reducerPath: "auth",
	baseQuery: baseQueryWithReauth,
}) */

export const apiSlice = createApi({
	reducerPath: "books",
	baseQuery: baseQueryWithReauth,
	tagTypes: ["DashboardBook", "Book", "User"],
	endpoints: (builder) => ({
		getBooks: builder.query({



			query: (queryParams) => { 
				const formattedQueryParams = {
                    ...queryParams,
                    filterTags: queryParams.filterTags.join(";"),
                    artistTags: queryParams.artistTags.join(";"),
                    publisherTags: queryParams.publisherTags.join(";"),
                }
				console.log(formattedQueryParams)
				return ({
					url: "/books",
					params: formattedQueryParams,
				})},
			providesTags: ["Book"],
		}),
		getBookById: builder.query({
			query: (id) => `/books/${id}`,
		}),
		submitBook: builder.mutation({
			query: (bookData) => ({
				url: "/books",
				method: "POST",
				body: bookData,
				prepareHeaders: (headers) => {
					headers.set("Content-Type", "multipart/form-data")

					return headers
				},
			}),
		}),
		getRelatedBooks: builder.query({
			query: (id) => `/books/related/${id}`,
		}),
		// admin/dashboard
		getDashboardBooks: builder.query({
			query: (queryParams) => ({
				url: "/dashboard",
				params: queryParams,
			}),
			providesTags: ["DashboardBook"],
		}),
		deleteBook: builder.mutation({
			query: (id) => ({
				url: `/dashboard/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["DashboardBook", "Book"],
		}),
		updateBook: builder.mutation({
			query: (payload) => ({
				url: `/dashboard/${payload.id}`,
				method: "PUT",
				body: payload.update,
			}),
			invalidatesTags: ["DashboardBook", "Book"],
		}),
		getUsers: builder.query({
			query: () => "/dashboard/users",
			providesTags: ["User"],
		}),
		registerUser: builder.mutation({
			query: (user) => ({ url: "/user/register", method: "POST", body: user }),
			invalidatesTags: ["User"],
		}),
		deleteUser: builder.mutation({
			query: (user) => ({ url: "user/delete", method: "DELETE", body: user }),
			invalidatesTags: ["User"],
		}),
		getArtists: builder.query({
			query: () => ({
				url: "/books/fields/artists",
				method: "GET",
			}),
		}),
		getPublishers: builder.query({
			query: () => ({
				url: "/books/fields/publishers",
				method: "GET",
			}),
		}),
		getThemes: builder.query({
			query: () => ({
				url: "/books/fields/themes",
				method: "GET",
			}),
		}),
		getGenres: builder.query({
			query: () => ({
				url: "/books/fields/genres",
				method: "GET",
			}),
		}),
	}),
})

export const {
	useGetBooksQuery,
	useSubmitBookMutation,
	useGetBookByIdQuery,
	useDeleteBookMutation,
	useGetRelatedBooksQuery,
	useGetDashboardBooksQuery,
	useUpdateBookMutation,
	useGetUsersQuery,
	useRegisterUserMutation,
	useDeleteUserMutation,
	useGetArtistsQuery,
	useGetPublishersQuery,
	useGetThemesQuery,
	useGetGenresQuery,
} = apiSlice
