import { Box, Button, Grid, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { removeCredentials } from "../reducers/authSlice"
import { useLocation, useNavigate } from "react-router-dom"

import { useLogoutMutation } from "../store/authApiSlice"

import { useGetDashboardBooksQuery } from "../store/apiSlice"
import DashBookItem from "./dashboard/dashBookItem"

import DashBoardSearchBar from "./dashboard/DashboardSearchBar"
import DropDownMenu from "./DropDownMenu"
import { dashboardFilterData, dashboardUserData } from "../data/dropdownData"
import { addFilter } from "../reducers/dashboardFilterSlice"
import TagItem from "./shared/TagItem"
import { useEffect, useState } from "react"
import ManageAdmins from "./dashboard/ManageAdmins"
import RegisterAdmin from "./dashboard/RegisterAdmin"
import useIsMobile from "./shared/useIsMobile"

const DashBoardView = ({ view }) => {
	const query = useSelector((state) => state.dashboardFilter.query)
	const [subView, setSubView] = useState("default")
	const { data, isFetching } = useGetDashboardBooksQuery(query)

	const dispatch = useDispatch()
	const [logout] = useLogoutMutation()
	const navigate = useNavigate()
	const location = useLocation()
	const auth = useSelector((state) => state.auth)

	useEffect(() => {
		setSubView(view)
	})

	if (auth.user) {
		var loginText = auth.user.username + " logged in"
	}
	const handleLogout = async () => {
		logout()
		dispatch(removeCredentials())
		navigate("/login")
	}

	const handleSelectFilter = (text) => {
		if (location !== "/dashboard") {
			navigate("/dashboard")
		}

		dispatch(addFilter(text))
	}

	const handleSelectManageUsers = (item) => {
		if (item.toLowerCase() === "register admin") {
			navigate("/dashboard/register_admin")
		} else if (item.toLowerCase() === "admins") {
			navigate("/dashboard/admins")
		}
	}

	const handleRemove = () => {
		dispatch(addFilter(""))
	}

	const isMobile = useIsMobile()

	if (isMobile) {
		return (
			<Box display={"flex"} padding={1}>
				Switch to the desktop version to access the dashboard.
			</Box>
		)
	}

	return (
		<>
			<Box
				display={"flex"}
				flexDirection={"column"}
				width={"25%"}
				position={"relative"}
				borderLeft={1}
			>
				<Box sx={{ overflow: "scroll", height: "calc(100vh - 235px)" }}>
					<DropDownMenu
						handleSelect={handleSelectFilter}
						dropDownData={dashboardFilterData}
					/>
					<DropDownMenu
						handleSelect={handleSelectManageUsers}
						dropDownData={dashboardUserData}
					/>
					<Box minHeight={"300px"} margin={"18px"}>
						{query.filter === "" ? null : (
							<TagItem text={query.filter} handleRemove={handleRemove} />
						)}
					</Box>
				</Box>
			</Box>

			<Box className="right-section">
				<Box className="fixed-nav" borderLeft={1} borderRight={1}>
					<DashBoardSearchBar />
					<Box className="layout">
						<Box
							display={"flex"}
							width={"100%"}
							alignItems={"center"}
							justifyContent={"space-between"}
							paddingLeft={3}
							paddingRight={3}
						>
							<Typography>{loginText}</Typography>

							<Button onClick={handleLogout}>Logout</Button>
						</Box>
					</Box>
				</Box>
				{subView === "default" ? (
					<Box id="collection-view" className="scrollable-content" border={1}>
						{isFetching ? (
							<Typography padding={2} variant="medium22">
								Updating..
							</Typography>
						) : (
							<Grid container>
								{data?.books?.map((book, index) => (
									<DashBookItem key={index} book={book}></DashBookItem>
								))}
							</Grid>
						)}
					</Box>
				) : subView === "admins" ? (
					<ManageAdmins />
				) : subView === "register_admin" ? (
					<RegisterAdmin />
				) : null}
			</Box>
		</>
	)
}

export default DashBoardView
