import { configureStore } from "@reduxjs/toolkit"
import layoutReducer from "../reducers/layoutSlice"
import filterReducer from "../reducers/filterSlice"
import submitBookReducer from "../reducers/submitBookSlice"
import dashboardFilterReducer from "../reducers/dashboardFilterSlice"

import logger from "redux-logger"
import { apiSlice } from "./apiSlice"
import authReducer from "../reducers/authSlice"

export const store = configureStore({
	reducer: {
		layout: layoutReducer,
		filter: filterReducer,
		submitBook: submitBookReducer,
		auth: authReducer,
		dashboardFilter: dashboardFilterReducer,
		[apiSlice.reducerPath]: apiSlice.reducer,
	},
	middleware: (getDefaultMiddleware) => {
		const ware = [apiSlice.middleware]
		if (process.env.REACT_APP_NODE_ENV === "development") {
			ware.push(logger)
		}

		return getDefaultMiddleware().concat(ware)
	},
	devTools: process.env.REACT_APP_NODE_ENV === "development" ? true : false,
})
