import { Box, ListItem, Typography, List, Slide } from "@mui/material"
import { useRef, useState } from "react"
import { DropDownCloseIcon, DropDownOpenIcon } from "./shared/Icons"
import { useDispatch, useSelector } from "react-redux"
//import { truncateString } from "./shared/truncateString"

const DropDownItem = ({ title, data, handleSelect }) => {
	const [isOpen, setIsOpen] = useState(false)
	const mode = useSelector((state) => state.layout.mode)
	const containerRef = useRef(null)
	const dispatch = useDispatch()

	const handleClick = () => {
		setIsOpen(!isOpen)
	}
	return (
		<>
			<Box className={"dropdown-item"} borderBottom={1} width={"100%"}>
				<Box
					height={"45px"}
					alignItems={"center"}
					display={"flex"}
					justifyContent={"space-between"}
					onClick={() => handleClick()}
				>
					<Typography paddingLeft={"18px"} variant="thin22">
						{title.toUpperCase()}
					</Typography>
					<Box margin={"4px"} display={"flex"} justifyContent={"center"}>
						{isOpen ? (
							<DropDownCloseIcon mode={mode} handleClick={handleClick} />
						) : (
							<DropDownOpenIcon mode={mode} handleClick={handleClick} />
						)}
					</Box>
				</Box>
				<Box ref={containerRef}>
					{isOpen ? (
						<List
							disablePadding
							sx={{ marginTop: "-6px", marginBottom: "6px" }}
						>
							{data &&
								data.map((item, index) => (
									<ListItem
										key={index}
										onClick={() => dispatch(() => handleSelect(item))}
										disablePadding
									>
										<Slide
											direction="down"
											in={isOpen}
											container={containerRef.current}
											mountOnEnter
											unmountOnExit
										>
											<Typography
												sx={{
													"&:hover": { opacity: 0.7 },
												}}
												marginLeft={"18px"}
												variant="thin16"
											>
												{item}
											</Typography>
										</Slide>
									</ListItem>
								))}
						</List>
					) : null}
				</Box>
			</Box>
		</>
	)
}

export default DropDownItem
