import LeftSection from "./secondary-view/LeftSection"
import MiddleSection from "./secondary-view/MiddleSection"
import RightSection from "./secondary-view/RightSection"
import RelatedContainer from "./book-details/RelatedContainer"
import ImageContainer from "./book-details/ImageContainer"
import DetailContainer from "./book-details/DetailContainer"

import { useMatch } from "react-router-dom"
import { useGetBookByIdQuery, useGetRelatedBooksQuery } from "../store/apiSlice"
import { Box } from "@mui/material"
import useIsMobile from "./shared/useIsMobile"
import MobileImageContainer from "./book-details/MobileImageContainer"

const DetailView = () => {
	const match = useMatch("/:id")
	const { data: book } = useGetBookByIdQuery(match.params.id)
	const { data: relatedBooks } = useGetRelatedBooksQuery(match.params.id)

	const isMobile = useIsMobile()

	if (isMobile) {
		return (
			<Box width={"100%"}>
				<Box display={"flex"} flexDirection={"column"}>
					<MobileImageContainer book={book}></MobileImageContainer>
					<DetailContainer book={book}></DetailContainer>
				</Box>
			</Box>
		)
	}

	return (
		<Box width={"100%"} borderBottom={1}>
			<Box display={"flex"}>
				<LeftSection
					component={<RelatedContainer relatedBooks={relatedBooks} />}
				/>
				<MiddleSection
					component={
						 <ImageContainer book={book} />
					}
				/>
				<RightSection
					component={
						 <DetailContainer book={book} />
					}
				/>
			</Box>
		</Box>
	)
}

export default DetailView
