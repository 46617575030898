import { Box, ImageList, ImageListItem } from "@mui/material"
import { useState, useCallback } from "react"
import ImageViewer from "react-simple-image-viewer"
import { useTheme } from "@mui/material/styles"

const PreviewImageView = ({ book }) => {
	const [currentImage, setCurrentImage] = useState(0)
	const [isViewerOpen, setIsViewerOpen] = useState(false)
	const theme = useTheme()

	const viewerBackgroundStyle = {
		backgroundColor: theme.palette.background.default,
	}

	const openImageViewer = useCallback((index) => {
		setCurrentImage(index)
		setIsViewerOpen(true)
	}, [])

	const closeImageViewer = () => {
		setCurrentImage(0)
		setIsViewerOpen(false)
	}

	const leftArrow = (
		<div style={{ color: theme.palette.primary.main }}>{"<"}</div>
	)
	const rightArrow = (
		<div style={{ color: theme.palette.primary.main }}>{">"}</div>
	)
	const closeIcon = <div style={{ color: theme.palette.primary.main }}>x</div>

	return (
		<>
			<Box overflow={"scroll"} height={"100%"}>
				<ImageList cols={1} sx={{ width: "100%", margin: "0" }}>
					{book &&
						book.images?.map((image, index) => (
							<>
								<ImageListItem
									onClick={() => openImageViewer(index)}
									key={image.id}
								>
									<img src={image.url}></img>
								</ImageListItem>
							</>
						))}
				</ImageList>
			</Box>

			{isViewerOpen && (
				<ImageViewer
					leftArrowComponent={leftArrow}
					rightArrowComponent={rightArrow}
					backgroundStyle={viewerBackgroundStyle}
					src={book.images?.map((img) => img.url)}
					currentIndex={currentImage}
					disableScroll={true}
					closeOnClickOutside={true}
					onClose={closeImageViewer}
					closeComponent={closeIcon}
				/>
			)}
		</>
	)
}

export default PreviewImageView
