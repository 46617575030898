import { Box, Typography } from "@mui/material"
import { Title, TitleValue } from "../book-details/Components"
import { DarkModeIcon, LightModeIcon } from "../shared/Icons"
import { useSelector } from "react-redux"
import CategoryTagBox from "../book-details/CategoryTagBox"
import SecondaryDetail from "../book-details/SecondaryDetail"

const PreviewDetailView = ({ book }) => {
	const mode = useSelector((state) => state.layout.mode)
	const itemStyle = {
		height: "45px",
		display: "flex",
		alignItems: "center",
	}

	const extraCategories = [
		{ "Illustrator/s": book?.illustrator ?? "" },
		{ "Graphic designer/s": book?.graphicDesigner ?? "" },
		{ "Photographer/s": book?.photographer ?? "" },
		{ "Writer/s": book?.writers ?? "" },
		{ "Editor/s": book?.editors ?? "" },
		{ "Proofreading": book?.proofreading ?? "" },
		{ "Language/s": book?.language ?? "" },
		{ "Repro": book?.repro ?? "" },
		{ "Printing technique": book?.printingTechnique ?? "" },
		{ "Binding technique": book?.bindingTechnique ?? "" },
		{ "Paper/s": book?.paper ?? "" },
		{ "Typeface/s": book?.typeface ?? "" },
		{ "Printing house": book?.printingHouse ?? "" },
		{ "Bindery": book?.bindery ?? "" },
		{ "ISBN": book?.isbn ?? "" }
	  ]
	const details = extraCategories
	.filter(category => {
		const title = Object.keys(category)[0]
		const value = category[title]
		return value !== null && value !== undefined
	})
	.map(category => {

		const title = Object.keys(category)[0]
		const value = category[title]
		return <SecondaryDetail key={title} title={title} value={value} />
	})
	if (book) {
		return (
			<Box overflow={"scroll"} height={"100%"}>
				<Box sx={itemStyle} paddingLeft={2}>
					<Title text={"TITLE"}></Title>
					<TitleValue text={book.title}></TitleValue>
				</Box>
				<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
					<Title text={"ARTIST"}></Title>
					<TitleValue text={book.artist}></TitleValue>
				</Box>
				<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
					<Box sx={itemStyle} width={"25%"} height={"45px"} borderRight={1}>
						<Title text={"EDITION"}></Title>
						<TitleValue text={book.edition}></TitleValue>
					</Box>
					<Box sx={itemStyle} width={"75%"} paddingLeft={2}>
						<Title text={"PAGE COUNT"}></Title>
						<TitleValue text={book.pageCount}></TitleValue>
					</Box>
				</Box>
				<Box sx={itemStyle} borderTop={1}>
					<Box
						sx={itemStyle}
						width={"50%"}
						height={"45px"}
						borderRight={1}
						paddingLeft={2}
					>
						<Title text={"DIMENSIONS"}></Title>
						<TitleValue text={book.dimensionX}></TitleValue>
						<Typography
							marginLeft={2}
							marginRight={2}
							variant="medium18"
							fontWeight={530}
						>
							x
						</Typography>
						<TitleValue text={book.dimensionY} />
						<Typography
							marginLeft={2}
							marginRight={2}
							variant="medium18"
							fontWeight={530}
						>
							cm
						</Typography>
					</Box>
					<Box
						sx={itemStyle}
						width={"23%"}
						height={"45px"}
						borderRight={1}
						paddingLeft={2}
					>
						<Title text={"YEAR"}></Title>
						<TitleValue text={book.year}></TitleValue>
					</Box>
					<Box
						sx={itemStyle}
						width={"27%"}
						height={"45px"}
						justifyContent={"center"}
					>
						<LightModeIcon mode={mode} />
						<DarkModeIcon mode={mode} />
					</Box>
				</Box>
				<Box
					display={"flex"}
					flexDirection={"column"}
					paddingTop={1}
					borderTop={1}
					height={"auto"}
					paddingLeft={2}
					paddingRight={2}
					paddingBottom={2}
					borderBottom={1}
				>
					<Title text={"DESCRIPTION"}></Title>
					<Typography whiteSpace={"pre-line"} paddingRight={2} variant="p">
						{book.description}
					</Typography>
				</Box>
				<Box marginTop={"1em"} marginBottom={"1em"}>

					<SecondaryDetail title={"Publisher"} value={book.publisher} />
					{details}

					<SecondaryDetail title={"Artist/s website"} value={book.artistWebsite}/>
					{book.availableToPurchase === true ? <SecondaryDetail 
													title={"Contact information for sale"} 
													value={book.contactInformation}/> 
													: null}
					</Box>
					
					<Box borderBottom={1}></Box>
					<CategoryTagBox categoryTags={book.categoryTags} />
				
			</Box>
		)
	} else {
		return <Box>No details available</Box>
	}
}

export default PreviewDetailView
