import { Box, Typography } from "@mui/material"

import BookCard from "../main-view/BookCard"

const RelatedContainer = ({ relatedBooks }) => {
	return (
		<>
			<Box
				display={"flex"}
				alignItems={"center"}
				paddingLeft={"8px"}
				height={"45px"}
				borderBottom={1}
				borderRight={1}
				width={"100%"}
				borderLeft={1}
			>
				<Typography fontSize={22}>RELATED</Typography>
			</Box>
			<Box
				width={"100%"}
				overflow={"scroll"}
				height={"calc(100vh - 235px - 45px)"}
				borderLeft={1}
				borderRight={1}
			>
				{relatedBooks &&
					relatedBooks.map((book, index) => (
						<BookCard
							isRelated={true}
							book={book}
							key={index}
							booksOnRow={5}
						></BookCard>
					))}
			</Box>
		</>
	)
}

export default RelatedContainer
